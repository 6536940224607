<template>
  <v-app style="max-width:1200px; margin: 0 auto;">
    <v-app-bar
      app
      color="primary"
      dark
      v-if="$store.getters.env=='local' && $store.getters.debug"
    >
      <div class="d-flex align-center" @click="$router.push('/')">
        CUA
      </div>

      <v-spacer></v-spacer>

      <v-btn
        @click="$router.push('/questions')"
        text
      >
        <span class="mr-2">Questions</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

 

    <v-main>
      <v-dialog v-model="app.disclaimer.display" persistent>
        <v-card>
          <v-card-title>DISCLAIMER</v-card-title>
          <v-card-text>
            This application and the content herein are currently under development.
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <template v-if="$store.getters.env=='local'">
              <v-btn @click="app.disclaimer.display=false; $router.push('/surveys/vte')">VTE</v-btn>
              <v-btn @click="app.disclaimer.display=false; $router.push('/surveys/cua')">CUA</v-btn>
            </template>
            <template v-else>
              <!-- <v-btn @click="app.disclaimer.display=false; $router.push('/surveys/vte')">continue</v-btn> -->
              <v-btn @click="app.disclaimer.display=false; $router.push('/surveys/cua')">continue</v-btn>
            </template>
            <v-spacer/>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <router-view v-if="!app.disclaimer.display"/>
    </v-main>
  </v-app>
</template>

<script>


export default {
  name: 'App',
  created: function(){
    this.$store.commit('api',{
      local: process.env.VUE_APP_LOCAL_API,
      development: process.env.VUE_APP_DEV_API,
      staging: process.env.VUE_APP_STAGING_API,
      production: process.env.VUE_APP_API,
    })
  },
  data: function(){
    return {
      app: {
        disclaimer: {
          display: process.env.NODE_ENV=='development' ? false : false
        }
      }
    }
  },
  computed: {
    language: function(){
      return this.$store.getters.language
    }
  },
  watch: {
    language: function(){
      this.$store.commit('language',this.language)
    }
  }
};
</script>

<style>
.debug{
  font-style: italic;
  color: red;
}
pre{
  white-space: pre-wrap;
}
body{
  background-color: #fafafa;
}
</style>